import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import adminApi from "../../api/adminApi";
import localStore from "../../store/local-store";
import "./index.less";

export default function AdminLogin() {
  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();

  const [mobile, setMobile] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const login = () => {
    if (mobile != "" && password != "") {
      const adminDto = {
        mobile: mobile,
        password: password,
      };
      adminApi
        .authApi(adminDto)
        .then((res) => {
          if (res.status == 200) {
            localStore.auth = res.data;
            messageApi.success("登录成功");
            // navigate("/admin/exam/read");
          } else {
            messageApi.error(res.data);
          }
        })
        .catch((error) => {
          messageApi.error(error.message);
        });
    }
  };

  return (
    <>
      {contextHolder}
      <div className="admin-login-container">
        <div className="container">
          <div className="form-container sign-in-container">
            <form>
              <h1>查看考试</h1>
              <input
                type="text"
                placeholder="手机号"
                value={mobile}
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
              />
              <input
                type="password"
                placeholder="请输入密码"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <a href="#"></a>
              <button type="button" onClick={login}>
                登录
              </button>
            </form>
          </div>
          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-right">
                <h1>Hello, Friend!</h1>
                <p>Enter your personal details and start journey with us</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
