import { tnAxios, StdResponse } from "../config/axios";
import { Examinee } from "../interface/examinee";

export const authApi = (param: any) => {
  return tnAxios.post(`/admin/auth`, param);
};

export const examReadApi = (eId: string, uId: string) => {
  return tnAxios.get(`/admin/exam/read/${eId}/${uId}`);
};

export const addExaminee = (examinee: Examinee) => {
  return tnAxios.post(`/admin/examinee`, examinee);
}

export const getExaminees = (eId: string) => {
  return tnAxios.get(`/admin/examinee/${eId}`);
}

export default {
  authApi,
  examReadApi,
  addExaminee,
  getExaminees
};
