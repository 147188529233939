import './index.less'
import { Select, message } from "antd";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getExams } from "../../api/examApi";
import { Exam } from "../../interface/exam";
import { getExaminees } from "../../api/adminApi";
import { Examinee } from "../../interface/examinee";

export default function ExamRead() {
  const navigate = useNavigate();
  const [examOptions, setExamOptions] = useState<any[]>([]);
  const [selectExamId, setSelectExamId] = useState<string>('');
  const [examineeOptions, setExamineeOptions] = useState<any[]>([]);
  const [selExamineeId, setSelExamineeId] = useState<string>('');

  useEffect(() => {
    let examOptions: any[] = [];
    getExams().then(res => {
      let exams: Exam[] = res.data || [];
      if (exams && exams.length > 0) {
        exams.forEach((item: Exam) => {
          examOptions.push({
            value: item.id,
            label: item.name
          })
        })
        setExamOptions(examOptions);
      }
    })
  }, [])

  const generateExaminees = (examId: string) => {
    let examineeOptions: any[] = [];
    getExaminees(examId).then(res => {
      let examinees: Examinee[] = res.data || [];
      if (examinees && examinees.length > 0) {
        examinees.forEach((item: Examinee) => {
          examineeOptions.push({
            value: item.id,
            label: item.name
          })
        })
        setExamineeOptions(examineeOptions);
      }
    })
  }

  const onChangeExam = (value: string) => {
    if (!!!value) {
      message.info("当前无选择值");
      return;
    }
    setSelectExamId(value);
    generateExaminees(value);
  }

  const onChangeExaminee = (value: string) => {
    if (!!!selectExamId) {
      message.info("请先选择考试");
      return;
    }
    if (!!!value) {
      message.info("当前无选择值");
      return;
    }
    setSelExamineeId(value);
    onConfirm(value);
  }

  const onConfirm = (value: string) => {
    navigate(`${selectExamId}/${value}`)
  }

  return (
    <div className="exam-read">
      <Select placeholder="请选择考试" onChange={onChangeExam} options={examOptions} style={{ width: '300px' }} />
      <Select placeholder="请选择考生" onChange={onChangeExaminee} options={examineeOptions} style={{ width: '200px' }} />
    </div>
  )
}