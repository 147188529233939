export const GLOBAL_NAMESPACE = "recruit";
export const AUTHORIZATION = `${GLOBAL_NAMESPACE}_authorization`;
class LocalStore {
  public get(key: string) {
    return JSON.parse(localStorage.getItem(key) || "null") || null;
  }

  public set(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public remove(key: string): void {
    localStorage.removeItem(key);
  }

  get auth() {
    return this.get(AUTHORIZATION);
  }

  set auth(value: string) {
    if (value == null) {
      this.remove(AUTHORIZATION);
      return;
    }
    this.set(AUTHORIZATION, value);
  }

  clear() {
    this.remove(AUTHORIZATION);
  }
}

export default new LocalStore();
