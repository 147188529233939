import "./index.less";

import examApi from "../../api/examApi";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadChangeParam, UploadFile } from "antd/es/upload/interface";
import { PlusOutlined } from "@ant-design/icons";
import { Radio, Input, Modal, Upload, Image, Button, message } from "antd";
import {
  Examination,
  ExamineeReply,
  Paper,
  Topic,
} from "../../interface/paper";
import PaperEnum from "../../enums/PaperEnum";
import localStore from "../../store/local-store";
import { UploadFileObj } from "../../interface/uploadFile";
import SparkMD5 from "spark-md5";

const { TextArea } = Input;

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export default function Exam() {
  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const [examineeReplies, setExamineeReplies] = useState<ExamineeReply[]>(
    localStore.get("replies")
  );
  const [examination, setExamination] = useState<Examination>(
    localStore.get("exam")
  );
  const [imageList, setImageList] = useState<UploadFile[][]>(
    localStore.get("images")
  );

  const [timerHours, setTimerHours] = useState<number>(0);
  const [timerMinutes, setTimerMinutes] = useState(0);
  const [timerSeconds, setTimerSeconds] = useState(0);
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [replyImages, setReplyImages] = useState<string[][]>([]);

  const [confirmSubmit, setConfirmSubmit] = useState<boolean>(false);

  // 修改回答文本
  const onHandleChangeReplyText = (newValue: string, index: number) => {
    const updates = [...examineeReplies];
    updates[index].text = newValue;
    updates[index].lastUpdateTime = dayjs().valueOf();
    updates[index].type = PaperEnum.REPLY_TYPE.TEXT;
    setExamineeReplies(updates);
  };

  // 修改回答图片
  const onHandleChangeReplyImage = async(
    file: any,
    newFileList: UploadFile[],
    index: number
  ) => {
    if (file.response != undefined && file.response.status == 200) {
      const path = file.response.data;
      file.name = path;
      file.image = await getMd5(file.originFileObj as RcFile);
      const newReply = [...examineeReplies];
      newReply[index].image += file.image+";";
      setExamineeReplies(newReply);
    }
    const updates = [...imageList];
    updates[index] = newFileList;
    // updates[index].forEach((item) => {
    //   newReply[index].image += item.name + ";";
    // });
 
    setImageList(updates);
  };

  const onHandlePreviewCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  useEffect(() => {
    setExamination(localStore.get("exam"));
    setExamineeReplies(localStore.get("replies"));
    setImageList(localStore.get("images"));
  }, []);

  // TODO 计时器最后要存到local
  useEffect(() => {
    const newTimer = setInterval(() => {
      setTimerSeconds((prevSeconds) => {
        if (prevSeconds === 59) {
          setTimerMinutes((prevMinutes) => {
            if (prevMinutes === 59) {
              setTimerHours((prevHours) => prevHours + 1);
              return 0;
            }
            return prevMinutes + 1;
          });
          return 0;
        }
        return prevSeconds + 1;
      });
    }, 1000);
    setTimer(newTimer);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", () => { });
  });

  const submitExam = () => {
    setConfirmSubmit(false);
    examApi
      .replyApi(examineeReplies)
      .then((res) => {
        if (res.status == 200) {
          messageApi.success("提交成功，可以退出了");
          setTimeout(() => {
            localStore.clear();
            navigate("/login");
          }, 1500);
          return;
        }
        messageApi.error(res.data);
      })
      .catch(() => {
        messageApi.error("提交失败，请检查网络状态");
      });
  };

  const handleChange = (info: any) => {
    console.log(info);
  };

  const getMd5 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      let filename: string = file.name;
      let suffix = filename.slice(filename?.lastIndexOf('.')) || '';

      let blobSlice = File.prototype.slice,
        chunkSize = 1024 * 1024 * 2,                             // Read in chunks of 2MB
        chunks = Math.ceil(file.size / chunkSize),
        currentChunk = 0,
        spark = new SparkMD5.ArrayBuffer();


      const loadNext = () => {
        let start = currentChunk * chunkSize,
          end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize;

        reader.readAsArrayBuffer(blobSlice.call(file, start, end));
      }
      reader.onload = (e) => {
        if(!e.target) return;
        spark.append(e.target.result as ArrayBuffer);                   // Append array buffer
        currentChunk++;

        if (currentChunk < chunks) {
          loadNext();
          return;
        }

        let md5 = spark.end();
        let objectName = `${md5}${suffix || ''}`;
        resolve(objectName);
      }

      loadNext();

    })

  return (
    <div className="layout-e">
      {contextHolder}
      <div className="layout-header">
        <div className="timer-container">
          <span className="timer">{timerHours}</span>
          <span>:</span>
          <span className="timer">{timerMinutes}</span>
          <span>:</span>
          <span className="timer">{timerSeconds}</span>
        </div>
        <div className="mid-e-container paper-name">
          {examination.paper?.name}
        </div>
        <div className="submit-container">
          <Button type="primary" onClick={() => setConfirmSubmit(true)}>
            提交
          </Button>
        </div>
        <Modal
          open={confirmSubmit}
          title="请确认是否提交"
          cancelText="否,继续作答"
          okText="提交并停止作答"
          onOk={submitExam}
          onCancel={() => setConfirmSubmit(false)}
        >
          <span>提交后无法再进入考试作答</span>
        </Modal>
      </div>
      <div className="exam-container">
        <div className="mid-e-container paper-container">
          {examination.paper?.topics?.map((topic, index) => (
            <div key={topic.id} className="topic">
              <div className="topic-title">
                <span className="order">{index + 1}.</span>
                {topic.type === PaperEnum.TOPIC_TYPE.TEXT ? (
                  <span className="title">{topic.content}</span>
                ) : (
                  <Image width={200} src={topic.content} />
                )}
              </div>
              {topic.kind === PaperEnum.TOPIC_KIND.RADIO ? (
                <div className="topic-options">
                  <Radio.Group
                    onChange={(e) =>
                      onHandleChangeReplyText(e.target.value, index)
                    }
                    value={examineeReplies[index].text}
                  >
                    {topic.options?.map((option, index) => (
                      <Radio key={index} value={option.content}>
                        <span className="order">
                          {PaperEnum.TOPIC_OPTION_ORDER[index]}.
                        </span>
                        <span className="option">{option.content}</span>
                      </Radio>
                    ))}
                  </Radio.Group>
                </div>
              ) : (
                <div className="topic-reply">
                  <div className="reply-text">
                    <TextArea
                      showCount
                      value={examineeReplies[index].text}
                      onChange={(e) =>
                        onHandleChangeReplyText(e.target.value, index)
                      }
                      maxLength={600}
                      placeholder="请输入你的答案"
                      autoSize={{ minRows: 3, maxRows: 5 }}
                    />
                  </div>
                  <div className="reply-image">
                    <Upload
                      headers={{
                        authorization: `${localStore.auth}`,
                      }}
                      action="https://recruit.tncet.com/api/exam/reply/file"
                      method="post"
                      listType="picture-card"
                      fileList={imageList[index]}
                      onPreview={handlePreview}
                      onChange={(e) => {
                        onHandleChangeReplyImage(e.file, e.fileList, index);
                      }}
                    >
                      {imageList[index].length >= 8 ? null : uploadButton}
                    </Upload>
                    <Modal
                      open={previewOpen}
                      title={previewTitle}
                      footer={null}
                      onCancel={onHandlePreviewCancel}
                    >
                      <img
                        alt="example"
                        style={{ width: "100%" }}
                        src={previewImage}
                      />
                    </Modal>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
