import { useEffect } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import localStore from "../../store/local-store";

export default function Justify() {
  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();

  useEffect(() => {
    const href = window.location.href;
    const hrefs: string[] = href.split("/justify/");
    if (hrefs[1] === "") {
      messageApi.error("错误地址，请与考官联系");
      // TODO 设置错误标识，拒绝发送所有请求
      return;
    }
    const url = hrefs[1];
    const eId = url.split("/")[0];
    const mobile = url.split("/")[1];
    localStore.set("eId", eId);
    localStore.set("mobile", mobile);
    navigate("/login");
  }, []);

  return <>{contextHolder}</>;
}
