import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./routes/login";
import Exam from "./routes/exam";
import Justify from "./routes/justify";
import localStore from "./store/local-store";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import AdminIndex from "./admin-routes";
import Error404 from "./routes/error/index404";
// import Justify from "./routes/justify";
// <Route path="/justify" element={<Justify />}></Route>

function App() {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  useEffect(() => {
    const href = window.location.href;
    // if (localStore.get("mobile") == "" || localStore.get("eId") == "") {
    //   messageApi.error("尚未认证，请先认证");
    //   if (href.includes("/admin/")) {
    //     navigate("/admin/login");
    //   } else if (href.includes("/justify")) {
    //     navigate("/justify");
    //   }
    // }
  }, []);

  return (
    <div>
      {contextHolder}
      <Routes>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/exam/*" element={<Exam />}></Route>
        <Route path="/admin/*" element={<AdminIndex />}></Route>
        <Route path="/justify/*" element={<Justify />}></Route>
        <Route path="/*" element={<Error404 />}></Route>
      </Routes>
    </div>
  );
}

export default App;
