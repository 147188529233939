import { Button, Input, Select, message } from "antd";
import { getExams } from "../../api/examApi";
import { Exam } from "../../interface/exam";
import { useState, useEffect } from "react";
import './index.less';
import { Examinee } from "../../interface/examinee";
import { addExaminee } from "../../api/adminApi";

export default function Register() {
    const [name, setName] = useState<string>('');
    const [mobile, setMobile] = useState<string>('');
    const [options, setOptions] = useState<any[]>([]);
    const [selectExamId, setSelectExamId] = useState<string>('');
    const [text, setText] = useState<string>('');

    useEffect(() => {
        let options: any[] = [];
        getExams().then(res => {
            let exams: Exam[] = res.data || [];
            if (exams && exams.length > 0) {
                setSelectExamId(exams[0].id);
                exams.forEach((item: Exam) => {
                    options.push({
                        value: item.id,
                        label: item.name
                    })
                })
                setOptions(options);
            }
        })
    }, [])

    const onChangeName = (e: any) => {
        setName(e.target.value);
    }

    const onChangeMobile = (e: any) => [
        setMobile(e.target.value)
    ]

    const onChangeExam = (value: string) => {
        if (!!!value) {
            message.info("当前无选择值");
            return;
        }
        setSelectExamId(value);
    }

    const onConfirm = () => {
        let examinee: Examinee = {
            name: name,
            mobile: mobile,
            eid: selectExamId
        }
        addExaminee(examinee).then(res => {
            if (!!res.data) {
                let url = res.data || '';
                let msg = "请将考试链接发送给考生，考试链接为:https://recruit.tncet.com/justify/" + url;
                setText(msg);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    return (
        <div className="register-container">
            <Input placeholder="请输入考生姓名" onChange={onChangeName} />
            <Input placeholder="请输入考生手机号" onChange={onChangeMobile} />
            <Select defaultValue={selectExamId} onChange={onChangeExam} options={options} />
            <Button type="primary" onClick={onConfirm} >确认</Button>
            <div className="text">{text || ""}</div>
        </div>
    )
}