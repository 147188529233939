import "./paper.less";
import { useState, useEffect } from "react";
import type { RcFile } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import { Radio, Input, Modal, Upload, Image, Button, message } from "antd";
import { Option } from "../../interface/paper";
import PaperEnum from "../../enums/PaperEnum";
import localStore from "../../store/local-store";
import adminApi from "../../api/adminApi";
import { ExaminationReadVo, TopicReadVo } from "../../interface/admin";
const { TextArea } = Input;

const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

export default function Paper() {
    const [messageApi, contextHolder] = message.useMessage();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");

    const [imageList, setImageList] = useState<UploadFile[][]>([
        [],
        [
            {
                uid: "-1",
                name: "image.png",
                status: "done",
                url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
            },
            {
                uid: "-2",
                name: "image.png",
                status: "done",
                url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
            },
            {
                uid: "-3",
                name: "image.png",
                status: "done",
                url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
            },
            {
                uid: "-4",
                name: "image.png",
                status: "done",
                url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
            },
            {
                uid: "-xxx",
                percent: 50,
                name: "image.png",
                status: "uploading",
                url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
            },
            {
                uid: "-5",
                name: "image.png",
                status: "error",
            },
        ],
        [],
        [],
        [],
        [],
        [],
    ]);

    const [exam, setExam] = useState<ExaminationReadVo>({} as ExaminationReadVo);

    const onHandlePreviewCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(
            file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
        );
    };

    useEffect(() => {
        const href = window.location.href;
        const hrefs: string[] = href.split("/exam/read/");
        if (hrefs[1] === "") {
            messageApi.error("错误地址，请与考官联系");
            // TODO 设置错误标识，拒绝发送所有请求
            return;
        }
        const url = hrefs[1];
        const eId = url.split("/")[0];
        const uId = url.split("/")[1];
        adminApi
            .examReadApi(eId, uId)
            .then((res) => {
                if (res.status == 200) {
                    messageApi.success("获取成功");
                    setExam(res.data);
                } else {
                    messageApi.error(res.data);
                }
            })
            .catch((error) => {
                messageApi.error(error.message);
            });
    }, []);

    return (
        <div className="layout-e">
            {contextHolder}
            <div className="layout-header">
                <div className="timer-container"></div>
                <div className="mid-e-container paper-name">{exam.paper?.name}</div>
                <div className="submit-container"></div>
            </div>
            <div className="exam-container">
                <div className="mid-e-container paper-container">
                    {exam.paper?.topics?.map((topic: TopicReadVo, index: number) => (
                        <div key={topic.id} className="topic">
                            <div className="topic-title">
                                <span className="order">{index + 1}.</span>
                                {topic.type === PaperEnum.TOPIC_TYPE.TEXT ? (
                                    <span className="title">{topic.content}</span>
                                ) : (
                                    <Image width={200} src={topic.content} />
                                )}
                            </div>
                            {topic.kind === PaperEnum.TOPIC_KIND.RADIO ? (
                                <div className="topic-options">
                                    <Radio.Group
                                        disabled
                                        value={exam.paper?.topics[index]?.examineeReply.text}
                                    >
                                        {topic.options?.map((option: Option, index: number) => (
                                            <Radio key={index} value={option.content}>
                                                <span className="order">
                                                    {PaperEnum.TOPIC_OPTION_ORDER[index]}.
                                                </span>
                                                <span className="option">{option.content}</span>
                                            </Radio>
                                        ))}
                                    </Radio.Group>
                                </div>
                            ) : (
                                <div className="topic-reply">
                                    <div className="reply-text">
                                        <TextArea
                                            disabled
                                            value={exam.paper?.topics[index]?.examineeReply.text}
                                            autoSize={{ minRows: 3, maxRows: 5 }}
                                        />
                                    </div>
                                    <div className="reply-image">
                                        <Image.PreviewGroup
                                            preview={{
                                                onChange: (current, prev) =>
                                                    console.log(
                                                        `current index: ${current}, prev index: ${prev}`
                                                    ),
                                            }}
                                        >
                                            {exam.paper?.topics[index]?.examineeReply.image
                                                ?.split(";")
                                                .map((url, index) => (
                                                    <Image key={index} width={200} src={url}></Image>
                                                ))}
                                        </Image.PreviewGroup>
                                        {/* <Upload
                      action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                      listType="picture-card"
                      fileList={imageList[index]}
                      onPreview={handlePreview}
                    ></Upload>
                    <Modal
                      open={previewOpen}
                      title={previewTitle}
                      footer={null}
                      onCancel={onHandlePreviewCancel}
                    >
                      <img
                        alt="example"
                        style={{ width: "100%" }}
                        src={previewImage}
                      />
                    </Modal> */}
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
