import "./index.less";
import dayjs from "dayjs";
import { message } from "antd";
import { useEffect, useState } from "react";
import { ExamineeReply } from "../../interface/paper";
import examApi from "../../api/examApi";
import localStore from "../../store/local-store";
import PaperEnum from "../../enums/PaperEnum";
import { useNavigate } from "react-router-dom";
import type { UploadFile } from "antd/es/upload/interface";

export default function Login() {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const [mobile, setMobile] = useState<string>("");

  const [code, setCode] = useState<string>("");
  const [hasGetCode, setHasGetCode] = useState<boolean>(false);
  const [canGetCode, setCanGetCode] = useState<boolean>(true);

  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [timerNumber, setTimerNumber] = useState<number>(1);

  useEffect(() => {
    setMobile(localStore.get("mobile"));
  }, []);

  const getCode = () => {
    if (!canGetCode) {
      messageApi.error("请勿重复获取验证码");
      return;
    }
    setCanGetCode(false);
    setHasGetCode(true);
    examApi.sendCodeApi(mobile, localStore.get("eId")).then((res) => {
      if (res.status == 200) {
        const newTimer = setInterval(() => {
          setTimerNumber((prevTimerNumber) => {
            if (prevTimerNumber >= 59) {
              setCanGetCode(true);
              // setHasGetCode(false);
              clearInterval(timer);
              return 1;
            } else {
              return prevTimerNumber + 1;
            }
          });
        }, 1000);
        setTimer(newTimer);
        messageApi.success("验证码发送成功");
      } else {
        messageApi.error(res.data);
        clearInterval(timer);
        setCanGetCode(true);
        setHasGetCode(false);
      }
    });
  };

  const login = () => {
    if (hasGetCode) {
      examApi
        .authApi(mobile, localStore.get("eId"), code)
        .then((res) => {
          if (res.status == 200) {
            messageApi.success("登录成功");
            const exam = res.data;
            localStore.set("exam", res.data);
            const replies: ExamineeReply[] = [];
            const images: UploadFile[][] = [];
            if (exam.paper?.topics) {
              for (let i = 0; i < exam.paper?.topics.length; i++) {
                const topic = exam.paper?.topics[i];
                images[i] = [];
                replies.push({
                  eid: exam.id,
                  tid: topic.id,
                  type: PaperEnum.REPLY_TYPE.TEXT,
                  text: "",
                  image: "",
                  lastUpdateTime: dayjs().valueOf(),
                });
              }
              localStore.set("replies", replies);
              localStore.auth =
                localStore.get("eId") + "/" + localStore.get("mobile");
              localStore.set("images", images);
            }
            navigate("/exam");
          } else {
            messageApi.error("" + res.data);
          }
        })
        .catch((error) => {
          messageApi.error("链接地址有误");
        });
    } else {
      messageApi.error("请先获取验证码");
    }
  };

  return (
    <div className="examinee-login-container">
      {contextHolder}
      <div className="container">
        <div className="form-container sign-in-container">
          <form action="#">
            <h1>进入考试</h1>
            <input
              type="text"
              placeholder="手机号"
              value={mobile}
              onChange={(e) => {
                setMobile(e.target.value);
              }}
            />
            <input
              type="text"
              placeholder="验证码"
              onChange={(e) => {
                setCode(e.target.value);
              }}
            />
            <a href="#"></a>
            <div className="operate-container">
              <button type="button" onClick={getCode}>
                {canGetCode ? "获取验证码" : timerNumber}
              </button>
              {hasGetCode ? (
                <button type="button" onClick={login}>
                  登录
                </button>
              ) : (
                ""
              )}
            </div>
          </form>
        </div>
        <div className="overlay-container">
          <div className="overlay">
            <div className="overlay-panel overlay-right">
              <h1>Hello, Friend!</h1>
              <p>Enter your personal details and start journey with us</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
