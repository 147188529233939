import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import localStore from "../store/local-store";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import AdminLogin from "./login";
import ExamRead from "./exam-read";
import Register from "./register";
import Paper from "./exam-read/paper";

export default function AdminIndex() {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  useEffect(() => {
    // if (localStore.auth == "" || localStore.auth == null) {
    //   messageApi.error("尚未认证，请先认证");
    //   navigate("/admin/login");
    // }
  }, []);

  return (
    <div>
      {contextHolder}
      <Routes>
        <Route path="/login" element={<AdminLogin />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/exam/read" element={<ExamRead />}></Route>
        <Route path="/exam/read/*" element={<Paper />}></Route>
      </Routes>
    </div>
  );
}
