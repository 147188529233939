enum TOPIC_TYPE {
  "TEXT",
  "IMAGE",
}

enum TOPIC_KIND {
  "RADIO",
  "ESSAY",
}

enum TOPIC_OPTION_ORDER {
  "A",
  "B",
  "C",
  "D",
  "E",
}

enum REPLY_TYPE {
  "TEXT",
  "IMAGE",
  "MIXED",
}

const IMAGE_UPLOAD_STATUS = {
  uploading: "uploading",
  error: "error",
  done: "done",
};

enum EXAMINEE_STATUS {
  "NOT_STARTED",
  "SUBMIT",
  "UNCOMMITTED",
}

export default {
  TOPIC_TYPE,
  TOPIC_KIND,
  TOPIC_OPTION_ORDER,
  REPLY_TYPE,
  IMAGE_UPLOAD_STATUS,
  EXAMINEE_STATUS,
};
