import localStore, { AUTHORIZATION } from "../store/local-store";
import axios, { AxiosResponse } from "axios";

export interface StdResponse<T = any> extends AxiosResponse<T> {
  message: string;
}

// 状态码定义
// const codeMessage = {
//     200: 'ok',
//     400: '请求数据错误',
//     401: '用户没有权限',
//     404: '请求的资源不存在',
//     500: '服务器异常',
//     503: '服务器过载或维护',
//     504: '网关超时',
// }

const tnAxios = axios.create({
  baseURL: "/api",
  timeout: 60000,
});

tnAxios.interceptors.request.use(
  (config) => {
    // 从本地Cookie获取token
    let auth = localStore.auth || "";
    config.headers.Authorization = auth;
    return config;
  },
  (err) => {
    console.log(err);
  }
);
tnAxios.interceptors.response.use(
  (response) => {
    if (response.headers["content-type"]?.indexOf("application/json") >= 0) {
      return response.data;
    }
    return response;
  },
  (err) => {
    const error = err.response;
    if (!error) {
      return Promise.reject("发生其他异常");
    }
    // console.log(error)
    if (error.status === 401) {
      // 未授权登录
      localStore.clear();
      // store.userStore.setIsLogin(false);
    }
    return Promise.reject(error);
  }
);

export { tnAxios };
