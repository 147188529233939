import { tnAxios, StdResponse } from "../config/axios";
import { ExamineeReply, Examination } from "../interface/paper";

export const replyApi = (
  replys: ExamineeReply[]
): Promise<StdResponse<any>> => {
  return tnAxios.post(`/exam/reply`, replys);
};

export const authApi = (
  mobile: string,
  eId: string,
  code: string
): Promise<StdResponse<Examination>> => {
  return tnAxios.post(`/exam/auth/${eId}/${mobile}/${code}`);
};

export const sendCodeApi = (mobile: string, eId: string) => {
  return tnAxios.post(`/exam/sendCode/${eId}/${mobile}`, {});
};

export const getExams = (): Promise<StdResponse<any>> => {
  return tnAxios.get(`/exam/exams`);
};

export default {
  replyApi,
  authApi,
  sendCodeApi,
  getExams,
};
